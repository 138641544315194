import React from "react";
import { graphql, Link } from "gatsby";
import { useForm, ValidationError } from "@formspree/react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import CountryDropdown from "../components/CountryDropdown";

function Form({ formElements, formButtonText, successMessage }) {
  const [state, handleSubmit] = useForm(process.env.GATSBY_CONTACT_FORM);
  if (state.succeeded) {
    return (
      <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
        <div className="relative mx-auto max-w-xl">
          <div className="mt-12 text-center font-serif text-3xl">
            <h1>{successMessage}</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
      <div className="relative mx-auto max-w-xl">
        <div className="mt-12">
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            {formElements.map((elem, idx) => {
              const et = elem.type;
              let type;
              let id;
              let prefix;
              if (et === "Email") {
                type = "email";
                id = "email";
                prefix = "Email";
              } else if (et === "Textbox") {
                type = "text";
                id = elem.title;
                prefix = elem.title;
              } else if (et === "Country") {
                return (
                  <CountryDropdown
                    key={idx}
                    title={elem.title}
                    placeholder={elem.placeholder}
                    isRequired={elem.isRequired}
                  />
                );
              } else if (et === "Dropdown") {
                id = elem.title;
                return (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor={id}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {elem.title}
                    </label>
                    <select
                      id={id}
                      name={id}
                      className="mt-1 block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-ag-pink1 focus:ring-ag-pink1"
                      defaultValue={elem.placeholder}
                      required={elem.isRequired}
                    >
                      {elem.dropdownOptions.map((elem, index) => (
                        <option key={index}>{elem}</option>
                      ))}
                    </select>
                  </div>
                );
              }
              return (
                <div key={idx} className="sm:col-span-2">
                  <label
                    htmlFor={id}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {elem.title}
                  </label>
                  <div className="mt-1">
                    <input
                      id={id}
                      type={type}
                      name={id}
                      placeholder={elem.placeholder}
                      required={elem.isRequired}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-ag-pink1 focus:ring-ag-pink1"
                    />
                  </div>
                  <ValidationError
                    prefix={prefix}
                    field={id}
                    errors={state.errors}
                  />
                </div>
              );
            })}
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full rounded-md border border-gray-300 py-3 px-4 shadow-sm focus:border-ag-pink1 focus:ring-ag-pink1"
                  defaultValue={""}
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                disabled={state.submitting}
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-ag-pink1 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-ag-pink1 focus:outline-none focus:ring-2 focus:ring-ag-pink1 focus:ring-offset-2"
              >
                {formButtonText}
              </button>
            </div>
            <div className="w-full text-center text-gray-500 sm:col-span-2">
              You can take note of our privacy policy{" "}
              <Link to="/privacy-notice" className="text-ag-pink1 underline">
                here
              </Link>
              .
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Contact({
  data: { contentfulContactPage, contentfulNavigationBar, contentfulFooter },
}) {
  const data = contentfulContactPage;
  return (
    <Layout footerElements={contentfulFooter}>
      <Header
        heading={data.heading}
        subheading={data.subheading}
        buttonList={false}
        image={data.headerImage}
        navBar={contentfulNavigationBar.navigationBarList}
      />
      <Form
        formElements={data.formElements}
        formButtonText={data.formButtonText}
        successMessage={data.successMessage}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulContactPage(node_locale: { eq: "en" }) {
      ...HeaderWithoutButtonsContact
      formElements {
        dropdownOptions
        isRequired
        title
        type
      }
      formButtonText
      successMessage
    }
    contentfulNavigationBar(node_locale: { eq: "en" }) {
      navigationBarList {
        linksToSlug
        title
      }
    }
    contentfulFooter(node_locale: { eq: "en" }) {
      footerElements {
        linksToSlug
        title
      }
    }
  }
`;
